<template>
	<div class="phi-credit">

		<div class="card phi-card">
			<div class="card-content">
				<p class="name" v-if="credit.person">{{ credit.person.firstname }} {{ credit.person.lastname }}</p>
				<p class="name" v-else>{{ credit.method ? credit.method.name : null }}</p>

				<div class="date" v-if="credit.date">
					<i class="material-icons">today</i> Fecha de pago: <small><i>{{ $date(credit.date) }}</i></small>
				</div>

				<div class="observations" style="margin-bottom: 25px"><i>{{ credit.observations }}</i></div>

				<!-- concepts -->
				<div class="concepts" v-for="debit in credit.debits" :key="debit.id">
					<span style="font-weight: bold">{{ debit.period.name }} {{ debit.sequence ? '#' + debit.sequence : null }}</span>

					<div class="row" v-for="price in debit.prices" :key="price.debit_price">
						<div class="details">
							{{ price.concept.name != price.name ? price.concept.name : '' }} {{ price.name }}
							<div class="observations" v-if="price.observations">{{ price.observations }}</div>
						</div>
						<div class="price">{{ price.payed | currency }}</div>
					</div>
				</div>
			</div>

			<!-- total -->
			<div class="total">
				PAGADO
				<span>{{ parseFloat(credit.value) | currency }}</span>
			</div>
		</div>

	</div>
</template>

<script>
export default{
  props: ['credit']
}
</script>

<style lang="scss" scoped>
$color: #009688;

.card {
	border-radius: 10px;
	border-top: 4px solid $color;

	.card-content {
		padding: 25px;

		.name {
			font-size: 19px;
			font-weight: bold;
			margin-bottom: 20px;
		}

		.date {
			opacity: .9;
			display: flex;
			font-size: 13px;
			margin-bottom: 5px;
			align-items: center;
			margin-bottom: 40px;

			.material-icons {
				opacity: .6;
				font-size: 19px;
				margin-right: 9px;
			}
		}

		.concepts {
			.row {
				display: flex;
				flex-wrap: wrap;
				padding: 10px 0;

				.details {
					width: 100px;
					flex-grow: 1;

				}

				.price {
					width: 100px;
					font-size: 13px;
					font-weight: bold;
					text-align: right;
					align-self: flex-star;
				}
			}

			.observations {
				opacity: .7;
				font-size: 14px;
				margin-top: 8px;
				text-align: justify;
				font-weight: normal;
			}
		}
	}


	.total {
		color: #fff;
		display: flex;
		padding: 20px;
		background: $color;
		align-items: center;
		justify-content: space-between;
		border-radius: 0 0 10px 10px;
	}
}
</style>
